<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <div>

        <FullCalendar
            ref="cc"
            :options="calendarOptions"
        />


        <b-modal v-model="eventModal" title="Detalhes da Solicitação" title-class="text-black font-18" hide-footer
                 body-class="p-4" header-class="p-3 bg-soft-info" class="v-modal-custom">
            <div class="event-details" id="form">
                <div class="d-flex mb-2">
                    <div class="flex-grow-1 d-flex align-items-center mb-3">
                        <div class="flex-shrink-0 me-3">
                            <i class="ri-calendar-event-line text-muted fs-16"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="d-block fw-semibold mb-0" id="event-start-date-tag">
                                {{ dateIso(this.event.start) }} - {{ event.endHour }}
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="flex-shrink-0 me-3">
                        <i class="ri-map-pin-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="d-block fw-semibold mb-0">
                            <span id="event-location-tag">
                                Mentorando: {{ this.event.title }}
                            </span>
                        </h6>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="flex-shrink-0 me-3">
                        <i class="ri-map-pin-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="d-block fw-semibold mb-0">
                            <span id="event-location-tag">
                                Módulo: {{ this.event.section }}
                            </span>
                        </h6>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <div class="flex-shrink-0 me-3">
                        <i class="ri-map-pin-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="d-block fw-semibold mb-0">
                            <span id="event-location-tag">
                                Conteúdo: {{ this.event.content }}
                            </span>
                        </h6>
                    </div>
                </div>
                <div class="d-flex mb-3" v-if="this.event.description !== ''">
                    <div class="flex-shrink-0 me-3">
                        <i class="ri-discuss-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1">
                        <p class="d-block text-muted mb-0" id="event-description-tag" v-html="this.event.description">
                        </p>
                    </div>
                </div>

                <div class="d-flex mb-3 justify-content-around" v-if="event.status === 1">
                    <div class="align-middle">
                        <input type="radio" value="3" v-model="data.status" id="confirm">
                        <label for="confirm" class="ml-2 form-label">Confirmar</label>
                    </div>
                    <div class="align-middle">
                        <input type="radio" value="2" v-model="data.status" id="cancel">
                        <label for="cancel" class="ml-2 form-label">Cancelar</label>
                    </div>
                </div>

                <div v-if="event.status === 3">
                    Para acessar ao link da mentoria <a :href="event.meet_link" target="_blank">clique aqui</a>.
                </div>

            </div>
            <div class="d-flex  justify-content-end" v-if="event.status === 1">
                <button type="button" class="btn btn-outline-danger" @click="eventModal = false">
                    Cancelar
                </button>
                <button type="button" class="btn btn-outline-success ml-2" id="save"
                        @click="save()">
                    Confirmar
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
import "@fullcalendar/core";
import "flatpickr/dist/flatpickr.css";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import FullCalendar from "@fullcalendar/vue";

import Layout from '@/components/layout/main.vue'

import moment from 'moment';
import 'moment/locale/pt-br';

import {categories, INITIAL_EVENTS} from "./utils";

// moment.locale('pt-br');

import http from '@/http'
import {dateIso} from "@/components/composables/functions";
import {ValidateForm} from "@/components/composables/cruds";
import {endLoading, startLoading} from "@/components/composables/spinners";

export default {
    data() {
        return {
            data: {
                status: ''
            },
            title: "Agenda",
            currentEvents: [],
            showModal: false,
            eventModal: false,
            eventEditModal: false,
            categories: categories,
            submitted: false,
            submit: false,
            newEventData: {},
            edit: {},
            deleteId: {},
            events: [],
            event: {
                title: '',
                start: '',
                description: '',
                leadName: '',
                leadId: '',
                status: '',
            },
            config: {
                wrap: true, // set wrap to true only when using 'input-group'
                altFormat: "M j, Y",
                altInput: true,
                dateFormat: "d M, Y",
                mode: "range",
            },
            timeConfig: {
                enableTime: false,
                altInput: true,
                dateFormat: "Z",
                altFormat: "d M, Y",
                mode: "range",
            },
            date2: null,
        };
    },
    components: {
        Layout,
        FullCalendar,
    },
    mounted() {
        this.handleDatesRender();
    },
    methods: {
        dateIso,

        handleDatesRender() {
            let calendarApi = this.$refs.cc.getApi();
            const dates = {
                start: this.getRangeDate(calendarApi.currentData.dateProfile.activeRange.start),
                end: this.getRangeDate(calendarApi.currentData.dateProfile.activeRange.end),
            }

            this.getEvents(dates);
        },

        getRangeDate(date) {
            const dataObj = new Date(date);
            return `${dataObj.getUTCFullYear()}-${dataObj.getUTCMonth() + 1}-${dataObj.getUTCDate() < 10 ? '0' + dataObj.getUTCDate() : dataObj.getUTCDate()} ${dataObj.getUTCHours() < 10 ? '0' + dataObj.getUTCHours() : dataObj.getUTCHours()}:${dataObj.getMinutes() < 10 ? '0' + dataObj.getMinutes() : dataObj.getMinutes()}`
        },

        getEvents(dates) {
            http.get('mentee-schedules/list-mentor')
                .then(response => {
                    this.events = response.data
                })
                .catch(e => {
                    console.error('Get Calendar: ', e.response);
                })
        },

        getInitialView() {
            if (window.innerWidth >= 768 && window.innerWidth < 1200) {
                return "timeGridWeek";
            } else if (window.innerWidth <= 768) {
                return "listMonth";
            } else {
                return "dayGridMonth";
            }
        },

        dataEvent(info) {
            this.event = {
                id: info.event.id,
                title: info.event.title,
                start: info.event._instance.range.start,
                content: info.event.extendedProps.content,
                section: info.event.extendedProps.section,
                endHour: info.event.extendedProps.endHour,
                status: info.event.extendedProps.status,
                mentee_id: info.event.extendedProps.mentee_id,
                meet_link: info.event.extendedProps.meet_link,
            };
            this.eventModal = true;
        },

        save() {
            startLoading('form', 'save');
            this.$store.commit('api/setSpinner', true);
            const formData = {
                id: this.event.id,
                mentee_id: this.event.mentee_id,
                status: this.data.status,
            };

            let required = {
                status: 'Status'
            }

            if (!ValidateForm(this.$root, formData, required)) {
                endLoading('form', 'save');
                this.$store.commit('api/setSpinner', false);
                return;
            }

            http.post(`mentee-schedules/update`, formData)
                .then(() => {
                    this.$notifySuccess('Solicitação confirmada com sucesso!');
                    const className = this.data.status == 3 ? 'bg-success text-white' : 'bg-danger text-white';
                    this.events = this.events.map(obj => {
                        if (obj.id == this.event.id) {
                            return {
                                ...obj,
                                className,
                                status: this.data.status,
                                meet_link: this.data.meet_link
                            };
                        }
                        return obj;

                    })
                    this.handleDatesRender();

                    this.eventModal = false;
                })
                .catch((e) => {
                    console.error('Update Mentee Scheduele: ', e.response);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

    },

    computed: {
        calendarOptions() {
            return {
                timeZone: "local",
                droppable: false,
                navLinks: true,
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    bootstrapPlugin,
                ],
                themeSystem: "bootstrap",
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek",
                },
                windowResize: () => {
                    this.getInitialView();
                },
                initialView: this.getInitialView(),
                events: this.events,
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                dateClick: false,
                eventClick: this.dataEvent,
                locale: 'pt-br',
                buttonText: {
                    today: 'Hoje',
                    month: 'Mês',
                    week: 'Semana',
                    day: 'Dia',
                    list: 'Lista',
                },
            }
        }
    }
};
</script>

<style scoped>

.fc-event.fc-daygrid-event.fc-daygrid-dot-event {
    font-size: 12px;
}

</style>
